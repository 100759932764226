import { ProductPropsShopify } from '@/types/Product';
import axios, { AxiosResponse } from 'axios';

const endpoint = `https://${
  process.env.NEXT_PUBLIC_SHOPIFY_API_ENDPOINT || ''
}/api/2023-07/graphql.json`;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Shopify-Storefront-Access-Token':
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN || '',
};
const shopifyAPI = {
  getProduct: (
    id: string,
    country = 'AU',
  ): Promise<AxiosResponse<{ data: { product: ProductPropsShopify } }>> => {
    const graphqlQuery = {
      operationName: 'getProductByID',
      query: `query getProductByID($id: ID!, $country: CountryCode!) @inContext(country: $country) {
        product(id: $id) {
          id
          handle
          descriptionHtml
          variants(first: 5) {
            edges {
              node {
                id
                title
                quantityAvailable
                priceV2 {
                  amount
                }
                compareAtPriceV2 {
                  amount
                }
                sku
                weight
              }
            }
          }
        }
      }`,
      variables: { id, country },
    };

    return axios({
      url: endpoint,
      method: 'post',
      headers,
      data: graphqlQuery,
    });
  },
};

export default shopifyAPI;
