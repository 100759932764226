import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const BASE_URL: string = process.env.NEXT_PUBLIC_API_BASE_URL || '';

const isTokenExpired = (token: string) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;

const getToken = (name: string) => {
  const localToken = localStorage.getItem(name);
  if (localToken) {
    if (name === 'refresh_token') {
      return localToken;
    }

    if (name === 'access_token') {
      if (isTokenExpired(localToken)) {
        localStorage.removeItem(name);
      } else {
        return localToken;
      }
    }
  }

  const env = process.env.NEXT_PUBLIC_API_ENV || '';
  const token = Cookies.get(`${env}_${name}`);
  if (token) {
    localStorage.setItem(name, token);
  }

  return localStorage.getItem(name);
};

const axiosApiService = axios.create();

// request interceptor to add the auth token header to requests
axiosApiService.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const newConfig = config;
    const accessToken = getToken('access_token');
    if (accessToken && newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

// response interceptor to refresh token on receiving token expired error
axiosApiService.interceptors.response.use(
  (response) => response,
  (error: { config: AxiosRequestConfig; response: AxiosResponse }) => {
    const originalRequest = error.config;
    const accessToken = getToken('access_token');
    const refreshToken = getToken('refresh_token');
    if (error.response.status === 401) {
      if (accessToken) {
        localStorage.removeItem('access_token');
      }

      if (refreshToken) {
        return axios
          .put(`${BASE_URL}/api/session`, { refresh_token: refreshToken })
          .then(
            (
              res: AxiosResponse<{
                data: {
                  access_token: string;
                  refresh_token: string;
                };
              }>,
            ) => {
              if (res.status === 200) {
                localStorage.setItem(
                  'access_token',
                  res.data.data.access_token,
                );
              }
              return axios(originalRequest);
            },
          )
          .catch(
            (refreshError: {
              config: AxiosRequestConfig;
              response: AxiosResponse;
            }) => {
              if (refreshError.response.status === 401) {
                localStorage.removeItem('refresh_token');
              }
            },
          );
      }
    }
    return Promise.reject(error);
  },
);

const hasSessionToken = () => {
  return getToken('access_token');
};

export { axiosApiService, hasSessionToken, BASE_URL };
