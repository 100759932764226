import { AxiosResponse } from 'axios';
import { axiosApiService, BASE_URL } from '@/lib/services/axiosApiService';
import IUser from '@/types/IUser';

const accountsApi = {
  getCurrentUser: (): Promise<AxiosResponse<{ data: IUser }>> => {
    return axiosApiService.get(`${BASE_URL}/api/current_user`);
  },
};

export default accountsApi;
