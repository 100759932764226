import { fetchAPI } from '@/lib/api';
import { StrapiMedia } from '@/lib/media';
import shopifyAPI from '@/lib/shopify';
import ProductProps from '@/types/Product';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ShopifyBuy, {
  Cart,
  CheckoutResourceCreate,
  CurrencyCode,
  CustomAttribute,
  LineItem,
} from 'shopify-buy';
import useAuth from './useAuth';

const shopifyEnv = process.env.NEXT_PUBLIC_SHOPIFY_ENV || 'production';

export interface CartItem extends LineItem {
  product?: {
    id?: string;
    variantId: string | number;
    name?: string;
    handle?: string;
    price?: string;
    comparePrice: string | null;
    discount: number;
    totalPrice?: string;
    totalComparePrice?: string;
    media?: StrapiMedia;
    badge?: string;
  };
}

export interface Products {
  id: number;
  attributes: ProductProps;
}

export interface ShopifyContextType {
  isShopifyLoading: boolean;
  currency: string;
  enabledPresentmentCurrencies: CurrencyCode[] | undefined;
  currencySymbol: string;
  products: Products[];
  selectCurrency: (currency: string) => void;
  checkout?: Cart;
  cart: { items: CartItem[] };
  addItemToCart: (
    id: string | number,
    quantity: number,
    customAttributes?: CustomAttribute[],
    checkoutFlow?: boolean,
  ) => void;
  updateItemInCart: (id: string | number, quantity: number) => void;
  removeItemFromCart: (lineItemIds: string[]) => void;
  customQuantity: number;
  selectCustomQuantity: (quantity: number) => void;
  customProductID: number;
  selectCustomProductID: (id: number) => void;
  setCheckoutEmail: (email: string) => void;
}

const ShopifyContext = createContext<ShopifyContextType>(
  {} as ShopifyContextType,
);

export function ShopifyProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const router = useRouter();
  const [isShopifyLoading, setIsShopifyLoading] = useState<boolean>(false);
  const [enabledPresentmentCurrencies, setEnabledPresentmentCurrencies] =
    useState<CurrencyCode[] | undefined>();
  const [products, setProducts] = useState<Products[]>([]);
  const [allProducts, setAllProducts] = useState<Products[]>([]);
  const noOfProducts = products?.length;
  const [currency, setCurrency] = useState<string>(
    enabledPresentmentCurrencies && enabledPresentmentCurrencies[0]
      ? enabledPresentmentCurrencies[0]
      : 'AUD',
  );
  const { user } = useAuth();

  const storefrontAccessToken = user
    ? process.env.NEXT_PUBLIC_SHOPIFY_TAPT_DASHBOARD_STOREFRONT_API_TOKEN
    : process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN;

  const client = ShopifyBuy.buildClient({
    domain: process.env.NEXT_PUBLIC_SHOPIFY_API_ENDPOINT || '',
    storefrontAccessToken: storefrontAccessToken || '',
  });

  let currencySymbol = '';
  let country = '';
  switch (currency) {
    case 'EUR':
      currencySymbol = '€';
      country = 'FR';
      break;
    case 'GBP':
      currencySymbol = '£';
      country = 'GB';
      break;
    case 'USD':
      currencySymbol = '$';
      country = 'US';
      break;
    case 'AUD':
      currencySymbol = '$';
      country = 'AU';
      break;
    case 'CAD':
      currencySymbol = '$';
      country = 'CA';
      break;
    case 'JPY':
      currencySymbol = '¥';
      country = 'JP';
      break;
    case 'NZD':
      currencySymbol = '$';
      country = 'NZ';
      break;
    default:
      currencySymbol = '$';
      country = 'AU';
      break;
  }

  const [customQuantity, setCustomQuantity] = useState<number>(1);
  const selectCustomQuantity = useCallback((quantity: number) => {
    setCustomQuantity(quantity);
  }, []);

  const [customProductID, setCustomProductID] = useState<number>(0);
  const selectCustomProductID = useCallback((quantity: number) => {
    setCustomProductID(quantity);
  }, []);

  const [checkout, setCheckout] = useState<Cart>();
  const [cart, setCart] = useState<{ items: CartItem[] }>({
    items: [],
  });

  const updateCart = useCallback(
    (newCheckout: Cart) => {
      const cartItems = newCheckout?.lineItems.map((lineItem) => {
        const product = allProducts.find((item) => {
          if (
            item.attributes.shopify?.product?.variants.edges.find(
              (variant) => variant.node.id === lineItem.variant.id,
            )
          ) {
            return true;
          }

          return false;
        });

        const variant =
          product?.attributes.shopify?.product?.variants.edges.find(
            (variantItem) => variantItem.node.id === lineItem.variant.id,
          )?.node;
        const itemPrice = variant?.priceV2.amount;
        const itemComparePrice = variant?.compareAtPriceV2?.amount
          ? variant.compareAtPriceV2.amount
          : null;
        const itemDiscount =
          lineItem.discountAllocations && lineItem.discountAllocations[0]
            ? Number(lineItem.discountAllocations[0].allocatedAmount.amount)
            : 0;
        const subTotalPrice =
          lineItem.quantity * Number(itemPrice) - itemDiscount;
        const subTotalComparePrice = itemComparePrice
          ? lineItem.quantity * Number(itemComparePrice)
          : null;

        return {
          ...lineItem,
          product: {
            id: product?.attributes.shopify.product.id,
            variantId: lineItem.variant.id,
            name: product?.attributes.name,
            handle: product?.attributes.shopify.product.handle,
            price: itemPrice,
            comparePrice: itemComparePrice,
            discount: itemDiscount,
            totalPrice: subTotalPrice.toFixed(2),
            totalComparePrice: subTotalComparePrice?.toFixed(2),
            media: product?.attributes.media.data[0],
            badge: product?.attributes.badge,
          },
        };
      });

      if (cartItems)
        setCart({
          items: cartItems,
        });
    },
    [allProducts],
  );

  const addItemToCart = useCallback(
    (
      variantId: string | number,
      quantity: number,
      customAttributes: CustomAttribute[] = [],
      checkoutFlow?: boolean,
    ) => {
      const lineItemsToAdd = [
        {
          variantId,
          quantity,
          customAttributes,
        },
      ];

      // Add an item to the checkout
      if (checkout) {
        setIsShopifyLoading(true);
        client.checkout
          .addLineItems(checkout.id, lineItemsToAdd)
          .then((res) => {
            setCheckout(res);
            return res;
          })
          .then((res) => {
            updateCart(res);
          })
          .finally(() => {
            setIsShopifyLoading(false);
            if (
              checkoutFlow &&
              typeof window !== 'undefined' &&
              window.location
            ) {
              window.location.href = checkout.webUrl;
            } else {
              router.push('/cart').finally(() => {});
            }
          });
      }
    },
    [router, checkout, updateCart],
  );

  const updateItemInCart = useCallback(
    (id: string | number, quantity: number) => {
      const lineItemsToUpdate = [
        {
          id,
          quantity,
        },
      ];

      // Add an item to the checkout
      if (checkout) {
        setIsShopifyLoading(true);
        client.checkout
          .updateLineItems(checkout.id, lineItemsToUpdate)
          .then((res) => {
            setCheckout(res);
            return res;
          })
          .then((res) => {
            updateCart(res);
          })
          .finally(() => {
            setIsShopifyLoading(false);
          });
      }
    },
    [checkout, updateCart],
  );

  const removeItemFromCart = useCallback(
    (lineItemIds: string[]) => {
      // Add an item to the checkout
      if (checkout) {
        setIsShopifyLoading(true);
        client.checkout
          .removeLineItems(checkout.id, lineItemIds)
          .then((res) => {
            setCheckout(res);
            return res;
          })
          .then((res) => {
            updateCart(res);
          })
          .finally(() => {
            setIsShopifyLoading(false);
          });
      }
    },
    [checkout, updateCart],
  );

  const createCheckout = useCallback(
    (input: CheckoutResourceCreate = { presentmentCurrencyCode: 'AUD' }) => {
      setIsShopifyLoading(true);
      client.checkout
        .create(input)
        .then((res) => {
          setCheckout(res);
          localStorage.setItem('checkoutId', String(res.id));
          return res;
        })
        .then((res) => updateCart(res))
        .finally(() => {
          setIsShopifyLoading(false);
        });
    },
    [updateCart],
  );

  const fetchCheckout = useCallback(
    (id: string) => {
      setIsShopifyLoading(true);
      client.checkout
        .fetch(id)
        .then((res) => {
          if (!res.completedAt) {
            setCheckout(res);
            localStorage.setItem('checkoutId', String(res.id));
            updateCart(res);
          } else {
            createCheckout();
          }
        })
        .catch(() => {
          createCheckout();
        })
        .finally(() => {
          setIsShopifyLoading(false);
        });
    },
    [createCheckout, updateCart],
  );

  const setCheckoutEmail = useCallback(
    (email: string) => {
      if (checkout) {
        setIsShopifyLoading(true);
        client.checkout
          .updateEmail(checkout.id, email)
          .then((res) => {
            setCheckout(res);
            return res;
          })
          .finally(() => {
            setIsShopifyLoading(false);
          });
      }
    },
    [checkout],
  );

  const selectCurrency = useCallback(
    (selectedCurrency: string) => {
      if (checkout && selectedCurrency !== '') {
        createCheckout({
          lineItems: checkout.lineItems.map((lineItem) => {
            return {
              variantId: lineItem.variant.id,
              quantity: lineItem.quantity,
            };
          }),
          presentmentCurrencyCode: selectedCurrency,
        });
      }
      setCurrency(selectedCurrency);
    },
    [checkout, createCheckout],
  );

  useEffect(() => {
    if (checkout && !checkout.email && user) {
      setCheckoutEmail(user.email);
    }
  }, [checkout, user, setCheckoutEmail]);

  useEffect(() => {
    const existingCheckoutId = localStorage.getItem('checkoutId');

    if (existingCheckoutId) {
      fetchCheckout(existingCheckoutId);
    } else {
      createCheckout();
    }
  }, [createCheckout, fetchCheckout]);

  useEffect(() => {
    // if (noOfProducts === 0) {
    setIsShopifyLoading(true);

    fetchAPI('/products', {
      sort: ['id:asc'],
    })
      .then(async (res) => {
        const data = await Promise.all(
          res.data.map(async (item) => {
            const productShopify = await shopifyAPI.getProduct(
              shopifyEnv === 'sandbox'
                ? `gid://shopify/Product/${
                    item.attributes.shopifyIDforSandbox as string
                  }`
                : `gid://shopify/Product/${
                    item.attributes.shopifyID as string
                  }`,
              country,
            );

            return {
              ...item,
              attributes: {
                ...item.attributes,
                shopify: productShopify.data.data,
                slug: item.attributes.slug,
              },
            };
          }),
        );
        setProducts(data as unknown as Products[]);

        const taptLite = data.find((item) => {
          return item?.attributes?.slug === 'tapt-lite';
        });
        if (taptLite) {
          const replacementCard = await shopifyAPI.getProduct(
            shopifyEnv === 'sandbox'
              ? `gid://shopify/Product/8743866892578`
              : `gid://shopify/Product/4696593334332`,
            country,
          );

          setAllProducts([
            ...data,
            {
              ...taptLite,
              attributes: {
                ...taptLite.attributes,
                name: 'Replacement',
                shopify: replacementCard.data.data,
              },
            },
          ] as Products[]);
        } else {
          setAllProducts(data as unknown as Products[]);
        }
      })
      .finally(() => {
        setIsShopifyLoading(false);
      });
    // }
  }, [noOfProducts, country]);

  useEffect(() => {
    client.shop
      .fetchInfo()
      .then((res) => {
        setEnabledPresentmentCurrencies(
          res.paymentSettings.enabledPresentmentCurrencies,
        );
      })
      .finally(() => {});
  }, []);

  const memoedValue = useMemo(
    () => ({
      isShopifyLoading,
      currency,
      enabledPresentmentCurrencies,
      currencySymbol,
      products,
      selectCurrency,
      checkout,
      setCheckout,
      cart,
      addItemToCart,
      updateItemInCart,
      removeItemFromCart,
      customQuantity,
      selectCustomQuantity,
      customProductID,
      selectCustomProductID,
      setCheckoutEmail,
    }),
    [
      isShopifyLoading,
      currency,
      enabledPresentmentCurrencies,
      currencySymbol,
      products,
      selectCurrency,
      checkout,
      cart,
      addItemToCart,
      updateItemInCart,
      removeItemFromCart,
      customQuantity,
      selectCustomQuantity,
      customProductID,
      selectCustomProductID,
      setCheckoutEmail,
    ],
  );

  return (
    <ShopifyContext.Provider value={memoedValue}>
      {children}
    </ShopifyContext.Provider>
  );
}

export default function useShopify(): ShopifyContextType {
  return useContext(ShopifyContext);
}
